import { DC_Repositories_Base_Enumerations_BusinessUnit } from '@monorepo-types/dc';
import { DC_Repositories_Base_Enumerations_Label } from '@monorepo-types/dc';
import { ResponseModels_Customers_Address_AddressModel } from '@monorepo-types/dc';

import { request } from '../client';

type GetAddressPublic = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  postalCode: string;
  houseNumber: string;
  houseNumberSuffix?: string;
  addServiceLocationId?: boolean;
};
/**
 * GetAddressPublic
 * Return a complete address, or an address with possible house number suffixes
 * @returns ResponseModels_Customers_Address_AddressModel Success
 */
export function getAddressPublic({
  businessUnit,
  label,
  postalCode,
  houseNumber,
  houseNumberSuffix,
  addServiceLocationId,
}: GetAddressPublic): Promise<ResponseModels_Customers_Address_AddressModel> {
  return request({
    method: 'GET',
    path: `/dxpweb/public/${businessUnit}/${label}/address`,
    query: { postalCode, houseNumber, houseNumberSuffix, addServiceLocationId },
    errors: { 400: 'Bad Request', 404: 'Not Found' },
  });
}

type GetAddressPublicV2 = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  postalCode: string;
  houseNumber: string;
  houseNumberSuffix?: string;
  addServiceLocationId?: boolean;
};
/**
 * GetAddressPublicV2
 * Return a complete address, or an address with possible house number suffixes
 * @returns ResponseModels_Customers_Address_AddressModel Success
 */
export function getAddressPublicV2({
  businessUnit,
  label,
  postalCode,
  houseNumber,
  houseNumberSuffix,
  addServiceLocationId,
}: GetAddressPublicV2): Promise<ResponseModels_Customers_Address_AddressModel> {
  return request({
    method: 'GET',
    path: `/dxpweb/public/v2/${businessUnit}/${label}/address`,
    query: { postalCode, houseNumber, houseNumberSuffix, addServiceLocationId },
    errors: { 400: 'Bad Request', 404: 'Not Found' },
  });
}
